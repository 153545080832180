import React, { useEffect, useState } from 'react';
import { FaTag } from 'react-icons/fa';
// import Toggle from 'react-toggle';
import api from '../../../services/api';

export default function SubscriptionModal({
  status, closeModal, plans = [], plan, paymentMethod, onSubmit, subscriptionAction,
}) {
  const [code, setCode] = useState('');
  const [validCode, setValidCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [percentOff, setPercentOff] = useState('');
  const [discountVisible, setDiscountVisible] = useState(false);
  const [amountOff, setAmountOff] = useState(null);
  const interval = 'yearly'; // disable monthly option

  const individualPlans = [plans.find((p) => p.name === 'Individual'), plans.find((p) => p.name === 'Individual Monthly')];
  const academyPlans = [plans.find((p) => p.name.includes('Academy')), plans.find((p) => p.name.includes('Academy Monthly'))];
  const [currentPlan, setCurrentPlan] = useState(plan);

  useEffect(() => {
    if (subscriptionAction === 'eda') setCurrentPlan(individualPlans.find((p) => p.interval === interval));
    else setCurrentPlan(academyPlans.find((p) => p?.interval === interval));
  }, [interval]);

  const percent_off = percentOff ? 1 - (Number(percentOff) / 100) : 1;

  const priceCalculate = (default_amount) => {
    let price = ((default_amount / 100) * percent_off).toFixed(2);
    if (amountOff) price -= amountOff;
    return price;
  };

  const handlePromoCode = () => {
    if (code) {
      api.promo_code(code.trim())
        .then((response) => {
          if (response.success) {
            setCodeError('');
            setPercentOff(response.percent_off);
            setAmountOff(response.amount_off ? (response.amount_off / 100) : null);
            setValidCode(code);
            setDiscountVisible(true);
          } else {
            setCode('');
            setCodeError('Promo Code is not valid.');
            setPercentOff('');
            setDiscountVisible(false);
          }
        });
    }
  };

  const handleSubmitSubscription = () => {
    if (!paymentMethod) return;
    if (paymentMethod.id) onSubmit(paymentMethod.id, validCode, interval);
  };

  if (!currentPlan) {
    return (
      <div className="new-modal payment-method-modal" onClick={closeModal}>
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <h4>No plan available.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="new-modal payment-method-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md" style={{ width: '500px' }}>
        <div className="modal-content">
          <div className="modal-header">
            {/* { (!account_type.toLowerCase().includes('team') && plan.plan_type !== 'check') && ( */}
            {/*  <div className="notifications-column"> */}
            {/*    Monthly */}
            {/*    {' '} */}
            {/*    <Toggle icons={false} checked={currentPlan.interval === 'yearly'} onChange={() => setInterval(interval === 'yearly' ? 'monthly' : 'yearly')} data-testid="toggle" /> */}
            {/*    {' '} */}
            {/*    Annual */}
            {/*  </div> */}
            {/* )} */}
            <div className="modal-title">
              <h4>
                Add
                {' '}
                {currentPlan.name}
                {' '}
                Subscription
              </h4>
              <button
                type="button"
                className="button-clear pull-right"
                onClick={closeModal}
              >
                ✕
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="pricing" style={{ marginTop: '1.1rem' }}>
                  <div className="pricing-row plan">
                    <div> Subscription plan </div>
                    <div>
                      { currentPlan.name }
                    </div>
                  </div>
                  <div className="row">
                    <div className={`form-group col-md-8 ${codeError ? 'has-error' : ''}`}>
                      <input
                        className="form-control"
                        type="text"
                        name="promo_code"
                        id="promo_code"
                        value={code}
                        onChange={(e) => {
                          setDiscountVisible(false);
                          if (e.target.value === '') {
                            setCodeError('');
                            setPercentOff('');
                          }
                          setCode(e.target.value);
                        }}
                        placeholder="Enter Promo Code..."
                      />
                      { codeError && <p className="help-block">{ codeError }</p> }
                    </div>
                    <div className="form-group col-md-4" style={{ marginTop: '0' }}>
                      <button type="button" className="btn btn-promocode" onClick={handlePromoCode}>Apply</button>
                    </div>
                  </div>
                  {discountVisible && (
                    <div className="pricing-row total">
                      <div>
                        Discount
                        <span className="discount">
                          <FaTag size={12} />
                          {' '}
                          {code}
                        </span>
                      </div>
                      <div>
                        -$
                        { ((currentPlan.default_amount / 100) * (1 - percent_off) + amountOff).toFixed(2)}
                      </div>
                    </div>
                  )}
                  <div className="pricing-row total">
                    <div>
                      Sub total
                    </div>
                    <div>
                      $
                      { priceCalculate(currentPlan.default_amount) }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={handleSubmitSubscription}
              type="button"
              className="modal-button"
              disabled={status === 'updating' || status === 'success' || status === 'subscribing'}
            >
              { status === 'updating' ? 'Updating' : status === 'subscribing' ? 'Subscribing' : 'Update' }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
