import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { GoSearch, FaBolt } from 'react-icons/all';
import { FadeLoader } from 'react-spinners';
import api from '../../services/api';

export default function AddDynamicCiteListSection({ closeSection }) {
  const [citeLists, setCiteLists] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [mode, setMode] = useState('create');
  const [newCiteListName, setNewCiteListName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [listErrors, setListErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchCiteLists = () => api.cite_lists.get({ withShared: true }).then((response) => {
    setCiteLists(response.message);
    setIsLoading(false);
  });

  useEffect(() => {
    fetchCiteLists();
  }, []);

  const createCiteList = async () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('page');
    setIsLoading(true);
    const { status, message } = await api.cite_lists.post({ name: newCiteListName, query: url.search });
    if (status === 'OK') {
      setListErrors('');
      setSuccessMessage(`Cite List named ${newCiteListName} was created successfully.`);
      await fetchCiteLists();
    } else {
      setListErrors(message);
      setSuccessMessage('');
      setIsLoading(false);
    }
    setMode('add');
  };

  const fetchSuggestions = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const suggestions = inputValue.length === 0 ? [] : citeLists.filter((item) => item.name.toLowerCase().slice(0, inputValue.length) === inputValue.toLowerCase());

    setSearchSuggestions(suggestions);
  };

  const clearSearchSuggestions = () => {
    setSearchSuggestions([]);
  };

  const searchInputProps = {
    placeholder: 'Search By Cite List Name',
    value: searchValue,
    onChange: (e, { newValue }) => setSearchValue(newValue),
  };

  const renderAdd = () => (
    <div className="add-to-cite-list side-section">
      {isLoading && (
        <div className="loader">
          <FadeLoader color="rgba(0, 14, 87, 1)" />
        </div>
      )}
      <div className="side-section-header">
        <h4 className="side-section-title">
          Save current search as Dynamic Cite List
          <button
            type="button"
            className="button-clear"
            onClick={closeSection}
          >
            ✕
          </button>
        </h4>
        <div className="create-cite-list-row">
          <p className="side-section-description">
            Search cite lists or click button to add a new dynamic cite list.
          </p>
          <button type="button" className="btn btn-primary" onClick={() => setMode('create')}>
            Add New
          </button>
        </div>
      </div>
      <div className="side-section-body">
        <div className="search">
          <Autosuggest
            suggestions={searchSuggestions}
            shouldRenderSuggestions={() => true}
            onSuggestionsFetchRequested={fetchSuggestions}
            onSuggestionsClearRequested={clearSearchSuggestions}
            getSuggestionValue={(item) => item.name}
            renderSuggestion={(item) => (
              <div className="suggestion">{ item.name }</div>
            )}
            inputProps={searchInputProps}
          />
          <GoSearch />
        </div>
        { successMessage && <div className="side-section-message success">{ successMessage }</div> }
        {listErrors !== '' && (
          <div className="side-section-message cite-list-warning">
            { listErrors }
          </div>
        )}
        {
          citeLists.map((citeList) => (
            <div key={citeList.id} className="side-section-item">
              <div className="side-section-item-name">
                {citeList.name}
                <span style={{ flex: 1 }} />
                { citeList.search_query && <FaBolt /> }
                { citeList.search_query ? citeList.search_count : citeList.cases_count }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );

  const renderCreate = () => (
    <div className="side-section add-to-cite-list">
      {isLoading && (
        <div className="loader">
          <FadeLoader color="rgba(0, 14, 87, 1)" />
        </div>
      )}
      <div className="side-section-header">
        <h4 className="side-section-title">
          Add New Dynamic Cite List
          <button
            type="button"
            className="button-clear"
            onClick={closeSection}
          >
            ✕
          </button>
        </h4>
      </div>
      <div className="side-section-body">
        <input
          className="new-cite-list-input"
          value={newCiteListName}
          onChange={(e) => setNewCiteListName(e.target.value)}
          placeholder="Enter Cite List Name"
        />
        <button
          type="button"
          disabled={!newCiteListName}
          className="btn btn-primary"
          onClick={createCiteList}
        >
          Create Cite List
        </button>
      </div>
    </div>
  );

  return mode === 'add' ? renderAdd() : renderCreate();
}
