// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AddToCiteListModal renders correctly with props 1`] = `
<div
  className="add-to-cite-list side-section"
>
  <div
    className="side-section-header"
  >
    <h4
      className="side-section-title"
    >
      Add Results to Cite List
      <button
        className="button-clear"
        onClick={[Function]}
        type="button"
      >
        ✕
      </button>
    </h4>
    <div
      className="create-cite-list-row"
    >
      <p
        className="side-section-description"
      >
        To add to an existing cite list, search cite lists, or click button to add a new cite list.
      </p>
      <button
        className="btn btn-primary"
        onClick={[Function]}
        type="button"
      >
        Add New
      </button>
    </div>
  </div>
  <div
    className="side-section-body"
  >
    <div
      className="search"
    >
      <div
        aria-expanded={false}
        aria-haspopup="listbox"
        aria-owns="react-autowhatever-1"
        className="react-autosuggest__container"
        role="combobox"
      >
        <input
          aria-activedescendant={null}
          aria-autocomplete="list"
          aria-controls="react-autowhatever-1"
          autoComplete="off"
          className="react-autosuggest__input"
          onBlur={[Function]}
          onChange={[Function]}
          onFocus={[Function]}
          onKeyDown={[Function]}
          placeholder="Search By Cite List Name"
          type="text"
          value=""
        />
        <div
          className="react-autosuggest__suggestions-container"
          id="react-autowhatever-1"
          role="listbox"
        />
      </div>
      <svg
        fill="currentColor"
        height="1em"
        stroke="currentColor"
        strokeWidth="0"
        style={
          Object {
            "color": undefined,
          }
        }
        viewBox="0 0 16 16"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
          fillRule="evenodd"
        />
      </svg>
    </div>
  </div>
</div>
`;
