import React from 'react';
import PropTypes from 'prop-types';
import {
  FiDownload, FaPlus, FaPencilAlt, FaBolt,
} from 'react-icons/all';
import ReactHtmlParser from 'react-html-parser';
import { GoSearch } from 'react-icons/go';
import {
  FaTimes, FaChevronUp, FaChevronDown, FaList, FaVideo, FaBookmark, FaRegBookmark, FaShareAlt,
} from 'react-icons/fa';
import moment from 'moment';
import { FadeLoader } from 'react-spinners';
import CaseDigestText from './CaseDigestText';
import api from '../../services/api';
import AddToCiteListSection from '../cite_lists/AddToCiteListSection';
import LoginModal from '../login_modal/LoginModal';
import { DecisionDateFormat } from '../../services/constants';
import VideoModal from '../video/VideoModal';
import CaseDigestPreview from './CaseDigestPreview';
import RelatedRules from '../related_items/RelatedRules';
import DownloadModal from '../download_modal/DownloadModal';
import PaywallModal from '../cite_lists/modals/PaywallModal';

class CaseDigest extends React.Component {
  constructor(props) {
    super(props);
    const announcementClosedAt = localStorage.getItem('CASE_DIGEST_ANNOUNCEMENT_CLOSED_AT');
    this.state = {
      searchKeywords: '',
      searchTotalCount: 0,
      activeSearchIndex: 0,
      citeModalOpen: false,
      citeModalResult: null,
      favorites: this.props.favorites || { case_digests: [] },
      loginModalOpen: false,
      nextDigestUrl: null,
      previousDigestUrl: null,
      videoModalOpen: false,
      videoModalResult: null,
      loadingModalOpen: false,
      downloadModalOpen: false,
      parsedAnnouncementText: ReactHtmlParser(props.announcementText),
      shouldShowAnnouncement: props.announcementTextEnabled
        && (!announcementClosedAt || props.announcementTextUpdatedAt > Number(announcementClosedAt)),
      searchOpened: false,
      downloadLimit: this.props.numberOfDownloads,
      paywallModalOpen: false,
      downloadAction: false,
    };
  }

  componentDidMount() {
    const { caseDigest } = this.props;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const openVideo = urlSearchParams.get('openVideo');

    this._scrollIntoHighlighted();
    this.setDigests();

    if (openVideo === 'true' && caseDigest.published_revision.video) {
      this._openVideoModal(caseDigest.published_revision.video);
    }
  }

  getSearchUrl = (results) => {
    const { caseDigest } = this.props;
    if (!results || Object.keys(results).length === 0) return '/case_law';

    const data = {
      q: results.query,
      court_ids: results.courtIds,
      daterange: results.startDate && results.endDate
        ? 'between' : results.startDate ? 'after' : results.endDate ? 'before' : '',
      start_date: results.startDate ? moment(results.startDate).format('MM/DD/YYYY') : '',
      end_date: results.endDate ? moment(results.endDate).format('MM/DD/YYYY') : '',
      sort_by: results.sortBy,
      page: results.page,
      results_per_page: 25,
      judge: results.judge,
      case_title: results.caseTitle,
      tag_ids_query: results.tagIdsQuery,
      clickedId: caseDigest.id,
    };
    const _params = new URLSearchParams();
    Object.keys(data).forEach((prop) => {
      if (data[prop] && data[prop].length === 0) return;
      _params.set(prop, data[prop]);
    });

    return `/case_law?${_params.toString()}`;
  };

  setDigests() {
    const { slugs } = JSON.parse(localStorage.getItem('CASE_DIGEST_SEARCH_RESULTS')) || {};
    if (!slugs || !slugs.length) return;

    const slug = window.location.pathname.split('/').pop();
    const idx = slugs.indexOf(slug);

    if (slugs[idx + 1]) this.setState({ nextDigestUrl: `/case_law/${slugs[idx + 1]}` });
    if (slugs[idx - 1]) this.setState({ previousDigestUrl: `/case_law/${slugs[idx - 1]}` });
  }

  _toggleCaseBookmark = (item) => {
    const { currentUser } = this.props;
    if (currentUser === null) {
      this._toggleLoginModal();
      return;
    }

    const { favorites } = this.state;
    const case_id = item?.case_digest?.id ? item.case_digest.id : item.id;

    if (favorites.case_digests !== undefined && favorites.case_digests.some((favorite) => favorite.id === case_id)) {
      api.case_digests.unfavorite(case_id).then(() => this._updateFavorites());
      return;
    }
    api.case_digests.favorite(case_id).then(() => this._updateFavorites());
  };

  _scrollIntoHighlighted = () => {
    const highlighted = document.getElementsByTagName('highlight');
    const rightDiv = document.querySelectorAll('.react-case-digest > .right')[0];
    const highlightedValue = new URLSearchParams(window.location.search).get('highlighted');
    if (highlighted && highlightedValue) {
      const result = Array.from(highlighted).find((h) => h.innerText === highlightedValue);
      if (result) rightDiv.scrollTo({ top: result.offsetTop - 60, behavior: 'smooth' });
    }
  };

  _toggleLoginModal = () => {
    const { loginModalOpen } = this.state;
    this.setState({ loginModalOpen: !loginModalOpen });
  };

  _updateFavorites = () => {
    api.favorites.get().then((result) => {
      this.setState({ favorites: result.favorites });
    });
  };

  _renderLoadingModal = () => (
    <div className="loading-modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="margin">
            <FadeLoader color="rgba(0, 14, 87, 1)" className="fade-loader" />
          </div>
          <div className="name">Loading</div>
        </div>
      </div>
    </div>
  );

  _downloadPDF = async (e, result) => {
    e.preventDefault();
    e.stopPropagation();

    const { currentUser, isACEDSTrial, plan_name } = this.props;
    const hasDownloadLimit = plan_name === 'Trial' || plan_name === 'Individual Monthly';
    const { downloadLimit } = this.state;
    if (currentUser === null) {
      this._toggleLoginModal();
      return;
    }

    if (isACEDSTrial) {
      this.setState({ paywallModalOpen: true });
      return;
    }

    if (hasDownloadLimit) this.setState({ downloadModalOpen: true });
    if (hasDownloadLimit && downloadLimit === 0) return;

    this.setState({ downloadLimit: downloadLimit - 1, downloadAction: true });
    const case_id = result.case_digest?.id ? result.case_digest.id : result.id;
    await api.case_digests.downloadPDF(case_id).catch(() => alert('Download failed'));
  };

  _closeAnnouncement = () => {
    window.localStorage.setItem('CASE_DIGEST_ANNOUNCEMENT_CLOSED_AT', new Date().valueOf());
    this.setState({ shouldShowAnnouncement: false });
  };

  _renderCiteModal = (result) => (
    <div className="side-section-container-float">
      <AddToCiteListSection
        caseDigestIds={result}
        closeSection={this._closeModal}
      />
    </div>
  );

  _openCiteModal = (e, result) => {
    e.preventDefault();
    e.stopPropagation();

    const { currentUser } = this.props;
    if (currentUser === null) {
      this._toggleLoginModal(e);
      return;
    }

    this.setState({ citeModalOpen: true, citeModalResult: result });
  };

  _closeModal = ({ citeModalOpen = false }) => {
    this.setState({
      citeModalOpen,
      videoModalOpen: false,
      videoModalResult: null,
    });
  };

  handleSearchKeywords = (e) => {
    this.setState({ activeSearchIndex: 0, searchKeywords: e.target.value });
  };

  handleSearchTotalCount = (count) => {
    this.setState({ activeSearchIndex: Math.min(1, count), searchTotalCount: count });
  };

  prevSearchIndex = () => {
    const { activeSearchIndex } = this.state;
    if (activeSearchIndex < 2) return;
    this.setState({ activeSearchIndex: activeSearchIndex - 1 });
  };

  nextSearchIndex = () => {
    const { activeSearchIndex, searchTotalCount } = this.state;
    if (activeSearchIndex >= searchTotalCount) return;
    this.setState({ activeSearchIndex: activeSearchIndex + 1 });
  };

  clearSearch = () => {
    this.setState({ searchKeywords: '', activeSearchIndex: 0, searchTotalCount: 0 });
  };

  onRelatedClick = (e) => {
    const { currentUser } = this.props;
    if (currentUser) return e;
    e.preventDefault();
    this._toggleLoginModal();
    return false;
  };

  _renderRelatedCase = (relatedCase) => {
    const { favorites } = this.state;
    return (
      <CaseDigestPreview
        caseDigest={relatedCase}
        onClick={this.onRelatedClick}
        onAddToList={(e) => this._openCiteModal(e, relatedCase.id)}
        onDownloadPdf={(e) => this._downloadPDF(e, relatedCase)}
        openVideoModal={this._openVideoModal}
        isRelated
        hasToListButton
        hasPDFButton
        hasTagList
        isFavorite={favorites.case_digests !== undefined && favorites.case_digests.some((digest) => (digest.id === relatedCase.id || digest.id === relatedCase.case_digest?.id))}
        onToggleFavorite={() => this._toggleCaseBookmark(relatedCase)}
      />
    );
  };

  _renderVideoModal = (result) => (
    <VideoModal video={result} closeModal={this._closeModal} />
  );

  _openVideoModal = (video) => {
    this.setState({ videoModalOpen: true, videoModalResult: video });
  };

  renderBackUrl() {
    const { currentUser } = this.props;
    if (!currentUser) return null;

    const { caseDigest } = this.props;
    const results = JSON.parse(localStorage.getItem('CASE_DIGEST_SEARCH_RESULTS'));
    const searchMode = results?.search_mode || '';
    let backUrl = '';
    let referrerString = '';

    switch (searchMode) {
      case 'case_law':
        backUrl = this.getSearchUrl(results);
        referrerString = 'to Search';
        break;
      case 'cite_list':
        backUrl = `${window.location.origin}/cite_lists/${results?.citeListId || ''}?clickedId=${caseDigest.id}`;
        referrerString = 'to Cite Lists';
        break;
      default:
        backUrl = window.document.referrer;
        break;
    }
    if (window.document.referrer.includes('dashboard')) {
      backUrl = `${window.location.origin}/dashboard`;
      referrerString = 'to Dashboard';
    }

    return (
      window.document.referrer && (new URL(window.document.referrer).hostname === window.location.hostname) && (
        <a className="nav-action" href={backUrl}>
          Back
          {' '}
          {referrerString}
        </a>
      )
    );
  }

  render() {
    const {
      // favorites,
      nextDigestUrl,
      previousDigestUrl,
      searchKeywords,
      searchTotalCount,
      activeSearchIndex,
      citeModalOpen,
      citeModalResult,
      loginModalOpen,
      videoModalOpen,
      videoModalResult,
      parsedAnnouncementText,
      shouldShowAnnouncement,
      loadingModalOpen,
      favorites,
      searchOpened,
      downloadModalOpen,
      downloadLimit,
      downloadAction,
      paywallModalOpen,
    } = this.state;
    const {
      caseDigest, scholarText, relatedCases, additionalFilings, isAdmin, currentUser, citeLists, plan_name, account_name,
    } = this.props;

    const rules = [...caseDigest.federal_rules, ...caseDigest.state_rules, ...caseDigest.district_rules, ...caseDigest.regulatory_rules];

    return (
      <>
        <div className="case-law-header">
          <div
            className="mobile-header-back"
            onClick={() => {
              // eslint-disable-next-line
              history.back()
            }}
          />
          <div className="mobile-header-cases">{ caseDigest.published_revision.name }</div>
          <div
            className="mobile-search-open"
            onClick={() => {
              this.setState({ searchOpened: !searchOpened });
            }}
          />
        </div>
        <div className="react-case-digest">
          <div className={`left ${searchOpened ? 'opened' : ''}`}>
            <div className="nav-actions">
              { this.renderBackUrl() }

              { (currentUser && previousDigestUrl) && (
                <a
                  className="nav-action"
                  href={previousDigestUrl}
                  onClick={(e) => { e.preventDefault(); window.location.replace(previousDigestUrl); }}
                >
                  ← Previous
                </a>
              )}

              { (currentUser && nextDigestUrl) && (
                <a
                  className="nav-action"
                  href={nextDigestUrl}
                  onClick={(e) => { e.preventDefault(); window.location.replace(nextDigestUrl); }}
                >
                  Next →
                </a>
              )}

            </div>
            <div className={`text-search ${searchTotalCount > 0 ? 'has-results' : ''}`}>
              <span className="text-search-icon"><GoSearch /></span>
              <input
                type="search"
                className="text-search-input"
                value={searchKeywords}
                onChange={this.handleSearchKeywords}
                placeholder="Search Text"
              />
              { searchTotalCount > 0 && (
                <span className="text-search-counter tag">
                  {activeSearchIndex}
                  /
                  {searchTotalCount}
                </span>
              )}
              <span className="text-search-actions">
                <FaChevronDown onClick={this.nextSearchIndex} />
                <FaChevronUp onClick={this.prevSearchIndex} />
                <FaTimes onClick={this.clearSearch} />
              </span>
            </div>
            <div className="name-row">
              <div className="name">
                { caseDigest.published_revision.name }
                { caseDigest.published_revision.video && (
                <FaVideo
                  onClick={(e) => { e.stopPropagation(); e.preventDefault(); this._openVideoModal(caseDigest.published_revision.video); }}
                  className="video"
                />
                )}
              </div>
              { isAdmin && (
                <button
                  className="edit-button"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    window.open(`/admin/case_digests/${caseDigest.id}/edit`, '_blank').focus();
                  }}
                >
                  <FaPencilAlt />
                </button>
              )}
              { !caseDigest.published_revision?.is_private && (
                <button
                  className="edit-button"
                  style={{ marginRight: '1rem' }}
                  title="Share on LinkedIn"
                  onClick={() => {
                    window.open(`https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(caseDigest.published_revision.full_text.replace(/<[^>]+>/g, ''))}&shareUrl=${encodeURIComponent(`${caseDigest.get_public_url}?${Number(Date.now())}`)}`, '_blank').focus();
                  }}
                >
                  <FaShareAlt />
                </button>
              )}
              <div className="bookmark" onClick={() => this._toggleCaseBookmark(caseDigest)}>
                { favorites.case_digests !== undefined && favorites.case_digests.some(
                  (digest) => digest.id === caseDigest.id,
                )
                  ? <FaBookmark />
                  : <FaRegBookmark />}
              </div>
            </div>
            <div className="cite">
              { caseDigest.published_revision.cite }
            </div>
            <div className="cite">
              {caseDigest.published_revision.decision_date && moment(caseDigest.published_revision.decision_date).format(DecisionDateFormat)}
            </div>
            <div onClick={(e) => {
              if (currentUser != null) return;
              e.preventDefault();
              this._toggleLoginModal(e);
            }}
            >
              { Boolean(caseDigest.published_revision.judge) && (
                <p className="cite">
                  <a className="cite" href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=${caseDigest.published_revision.judge}`}>
                    {caseDigest.published_revision.judge}
                  </a>
                  {`${caseDigest.published_revision.judge === 'Panel' && !caseDigest.published_revision.judge_type ? '' : ','}
                    \u00a0${caseDigest.published_revision.judge_type}`}
                </p>
              )}
            </div>
            <div className="tag-list">
              {
                caseDigest.tags?.map((tag) => (
                  <div
                    className="tag"
                    key={tag.id}
                    onClick={() => {
                      const url = `/case_law?sort_by=des_decision_date&page=1&results_per_page=25&include_unpublished=true&type=issue&tag_ids_query=${tag.id}`;
                      window.open(url, '_blank');
                    }}
                  >
                    {tag.name}
                  </div>
                ))
              }
            </div>
            <div className="buttons">
              <div
                className="pdf"
                onClick={(e) => this._downloadPDF(e, caseDigest)}
              >
                <FiDownload />
                Download PDF
              </div>
              <div className="cite" onClick={(e) => this._openCiteModal(e, [caseDigest.id])}>
                <FaPlus />
                To Cite List
              </div>
            </div>
            {
              caseDigest.published_revision.full_text && (
              <div className="digest">
                <div className="name">Summary</div>
                <div className="digest-content">
                  { ReactHtmlParser(caseDigest.published_revision.full_text) }
                </div>
              </div>
              )
            }
            { citeLists.length !== 0 && (
            <div className="digest-lists">
              Cite List Collection
              {
                citeLists.map((citeList) => (
                  <div
                    key={citeList.id}
                    onClick={(e) => {
                      if (currentUser != null) return;
                      e.preventDefault();
                      this._toggleLoginModal(e);
                    }}
                  >
                    <FaList />
                    <a href={`/cite_lists/${citeList.id}`}>{citeList.name}</a>
                    { citeList.search_query ? <FaBolt /> : null }
                  </div>

                ))
              }
            </div>
            )}
            {
              rules.length !== 0 && (
              <div className="related-rules">
                <div className="name related">Related Rules</div>
                <RelatedRules
                  rules={rules}
                  currentUser={currentUser}
                  onUnauthenticated={(e) => this._toggleLoginModal(e)}
                />
              </div>
              )
            }
            { additionalFilings.length !== 0 && (
              <div className="other-decisions">
                <div className="name">Additional Decisions</div>
                <div className="other-decisions-list">
                  { additionalFilings.map((relatedCase) => this._renderRelatedCase(relatedCase)) }
                </div>
              </div>
            )}
            { relatedCases.length !== 0 && (
              <div className="other-decisions">
                <div className="name related">Related Cases</div>
                <div className="other-decisions-list">
                  { relatedCases.map((relatedCase) => this._renderRelatedCase(relatedCase)) }
                </div>
              </div>
            )}
          </div>
          <div className="right">
            {
              caseDigest.published_revision.unpublished && (
              <div className="unpublished">
                Note: This is an unpublished decision. Check your jurisdiction’s rules about citing unpublished decisions before citing this case to a court.
              </div>
              )
            }
            {
              shouldShowAnnouncement && (
                <div className="case-law-announcement-bar">
                  <img alt="plus" src="/bulb.png" />
                  {parsedAnnouncementText}

                  <button type="button" className="close-btn" onClick={this._closeAnnouncement}>
                    &#x2716; &nbsp; Got it thanks
                  </button>
                </div>
              )
            }
            <CaseDigestText
              className={`scholar-text ${caseDigest.published_revision.google_scholar_text_type === 'scholar' ? 'format-one' : 'format-two'}`}
              text={scholarText}
              searchKeywords={searchKeywords}
              activeSearchIndex={activeSearchIndex}
              totalCountChanged={this.handleSearchTotalCount}
            />
          </div>
          { citeModalOpen && this._renderCiteModal(citeModalResult) }
          { loginModalOpen && <LoginModal closeModal={this._toggleLoginModal} /> }
          { videoModalOpen && this._renderVideoModal(videoModalResult) }
          { loadingModalOpen && this._renderLoadingModal() }
          { downloadModalOpen && (
            <DownloadModal
              isDownload={downloadAction}
              plan_name={plan_name}
              closeModal={() => this.setState({ downloadModalOpen: false, downloadAction: false })}
              numberOfDownloads={downloadLimit}
            />
          )}
          { paywallModalOpen && (
            <PaywallModal
              isDownload
              account_name={account_name}
              closeModal={() => {
                this.setState({ paywallModalOpen: false });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

CaseDigest.propTypes = {
  currentUser: PropTypes.object,
  caseDigest: PropTypes.object.isRequired,
  citeLists: PropTypes.array.isRequired,
  scholarText: PropTypes.string.isRequired,
  relatedCases: PropTypes.array.isRequired,
  additionalFilings: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  announcementTextEnabled: PropTypes.bool.isRequired,
  announcementText: PropTypes.string.isRequired,
  announcementTextUpdatedAt: PropTypes.number.isRequired,
};

CaseDigest.defaultProps = {
  currentUser: null,
};

export default CaseDigest;
