import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { GoPlus, GoSettings } from 'react-icons/go';
import { FiTrash } from 'react-icons/fi';
import Api from '../../../services/api';
import Select from '../Select';
import Table from '../users/Table';
import MoveToAccountSidebar from '../users/MoveToAccountSidebar';
import Dropdown, { DropdownItem } from '../Dropdown';
import Snackbar from '../../../services/snackbar';

const formattedFieldName = (field) => String(field).split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

const formattedFields = (fields) => Object.keys(fields)
  .map((key) => `${formattedFieldName(key)} (${formattedFieldName(fields[key]?.previous)} -> ${formattedFieldName(fields[key]?.current)})`)
  .join(', ');

function formatDate(inputDate) {
  const date = new Date(inputDate);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const activityMessage = (activity) => {
  const { data, user, action } = activity;
  const { options } = data;
  const updatedBy = `${user.full_name} [${user.is_admin ? 'Admin' : 'User'}]`;

  switch (action) {
    case 'created_user': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`created user ${options.updated_user_name}`}
          {' '}
          {options?.changed_user_fields?.subscription ? `with ${options.changed_user_fields.subscription} subscription` : ''}
        </>
      );
    }
    case 'deleted_user': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`deleted user ${options.updated_user_name}`}
        </>
      );
    }
    case 'disabled_user': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`${options?.changed_user_fields?.disabled ? 'disabled' : 'enabled'} user ${options.updated_user_name}`}
        </>
      );
    }
    case 'user_2fa_enabled': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`${options?.changed_user_fields?.two_factor_enabled ? 'enabled' : 'disabled'} 2fa for user ${options.updated_user_name}`}
        </>
      );
    }
    case 'user_confirmation': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`sent confirmation to ${options.updated_user_name}`}
        </>
      );
    }
    case 'user_subscription_or_account_updated': {
      const current_account = options?.changed_user_fields?.current_account;
      const previous_account = options?.changed_user_fields?.previous_account;
      const current_subscription = options?.changed_user_fields?.current_subscription;
      const previous_subscription = options?.changed_user_fields?.previous_subscription;
      const accountMessage = `moved ${options?.updated_user_name} to ${current_account}${previous_account ? ` from ${previous_account}` : ''}`;
      const subscriptionMessage = `${current_account ? ', and' : ''} changed subscription ${!current_account && !previous_account ? `for user ${options?.updated_user_name}` : ''} ${previous_subscription ? ` from ${previous_subscription}` : ''} to ${current_subscription}`;
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {current_account || previous_account ? accountMessage : ''}
          {current_subscription || previous_subscription ? subscriptionMessage : ''}
        </>
      );
    }
    case 'created_subscription': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`created ${options?.plan} subscription`}
        </>
      );
    }
    case 'reactivated_subscription': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`reactivated ${options?.plan} subscription`}
        </>
      );
    }
    case 'canceled_subscription': {
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`canceled ${options?.plan} subscription`}
        </>
      );
    }
    case 'changed_account_field': {
      const changedFields = formattedFields(data.changed_fields);
      return (
        <>
          <strong>{updatedBy}</strong>
          {' '}
          {`updated ${changedFields}`}
        </>
      );
    }
    case 'changed_user_field': {
      const changedUserFields = formattedFields(options?.changed_user_fields);
      const isSelf = options.updated_user_id === user.id;
      const message = isSelf
        ? `updated ${changedUserFields}`
        : `updated user ${options.updated_user_name}: ${changedUserFields}`;

      return (
        <>
          <strong>
            {isSelf ? options.updated_user_name : user.full_name}
            {user.is_admin ? ' [Admin]' : ' [User]'}
          </strong>
          {' '}
          {message}
        </>
      );
    }
    default:
      return 'Cannot process action type.';
  }
};

export default function Edit({
  account, account_activities, accountTypes, accountCategories, plans,
}) {
  const [data, setData] = useState({
    name: account?.name || '',
    account_type: account?.account_type || '',
    category_id: account?.category_id || null,
    first_name: account?.first_name || '',
    last_name: account?.last_name || '',
    primary_email: account?.primary_email || '',
    phone_number: account?.phone_number || '',
    secondary_first_name: account?.secondary_first_name || '',
    secondary_last_name: account?.secondary_last_name || '',
    secondary_email: account?.secondary_email || '',
    secondary_phone_number: account?.secondary_phone_number || '',
    notes: account?.notes || '',
    lead_source: account?.lead_source || '',
  });

  const [tab, setTab] = useState('account');
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [moveUserSidebarVisible, setMoveUserSidebarVisible] = useState(false);
  const [moveUser, setMoveUser] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [userFilters, setUserFilters] = useState({
    name: '', type: '', plan: '', confirmed: '', disabled: '',
  });
  const [subscriptionFilters, setSubscriptionFilters] = useState({ plan: '', status: '' });

  const trialUsers = filteredUsers.filter((u) => u.subscription?.plan?.plan_type === 'trial');
  // const subscriptions = account?.subscriptions.filter((s) => s?.plan.plan_type !== 'trial') || [];
  const [subscriptions, setSubscriptions] = useState(account?.subscriptions || []);

  const fetchUsers = () => {
    Api.admin.users.get({ page: 1, pageSize: 1000, accountId: account.id }).then((res) => {
      if (res.status !== 'OK') return;
      setUsers(res.data.users);
      setFilteredUsers(res.data.users);
    });
  };

  const handleUserSearch = () => {
    if (users.length === 0) return null;

    const {
      name, type, plan, confirmed, disabled,
    } = userFilters;
    if (name === '' && type === '' && plan === '' && confirmed === '' && disabled === '') return fetchUsers();

    let currentUsers = [...users];
    if (name) {
      currentUsers = currentUsers.filter((user) => `${user.first_name} ${user.last_name}`.toLowerCase().includes(name.toLowerCase())
        || user.email.includes(name.toLowerCase()));
    }
    if (type) currentUsers = currentUsers.filter((user) => user.account.account_type === type);
    if (plan) currentUsers = currentUsers.filter((user) => String(user.subscription?.plan_id) === plan || String(user.academy_subscription?.plan_id) === plan);
    if (confirmed !== '') currentUsers = currentUsers.filter((user) => String(user['confirmed?']) === confirmed);
    if (disabled !== '') currentUsers = currentUsers.filter((user) => String(user.disabled) === disabled);
    return setFilteredUsers(currentUsers);
  };

  const handleSubscriptionsSearch = () => {
    const { plan, status } = subscriptionFilters;
    if (plan === '' && status === '') return setSubscriptions(account?.subscriptions || []);

    let currentSubscriptions = account?.subscriptions || [];
    if (plan) currentSubscriptions = currentSubscriptions.filter((sub) => String(sub.plan.id) === plan);
    if (status) currentSubscriptions = currentSubscriptions.filter((sub) => sub.status === status);
    return setSubscriptions(currentSubscriptions);
  };

  useEffect(() => { if (account) fetchUsers(); }, [account]);

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleEnableUser = (user) => {
    Api.admin.users.enable(user.id).then((res) => {
      if (res.status !== 'OK') return;
      sessionStorage.setItem('message', `User ${user.first_name} ${user.last_name} was enabled`);
      window.location.reload();
    });
  };

  const handleDisableUser = (user) => {
    Api.admin.users.disable(user.id).then((res) => {
      if (res.status !== 'OK') return;
      sessionStorage.setItem('message', `User ${user.first_name} ${user.last_name} was disabled`);
      window.location.reload();
    });
  };

  const handleUpdate = () => {
    if (account) {
      Api.admin.accounts.update(account.id, data).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', 'Account updated');
        window.location.reload();
      });
    } else {
      Api.admin.accounts.create(data).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', 'Account created');
        window.location.href = `/admin/accounts/${res.account.id}/edit`;
      });
    }
  };

  const handleDelete = () => {
    Api.admin.accounts.delete(account.id).then(({ status }) => {
      if (status !== 'OK') return;

      sessionStorage.setItem('message', `Account “${account.first_name} ${account.last_name}” has been deleted!`);
      window.location.href = '/admin/accounts';
    });
  };

  const handleCancelSubscription = (subscription) => {
    Api.admin.subscriptions.cancel(subscription.id).then(({ status }) => {
      if (status !== 'OK') return;

      sessionStorage.setItem('message', 'Subscription has been canceled!');
      window.location.reload();
    });
  };

  const handleReactivateSubscription = (subscription) => {
    Api.admin.subscriptions.reactivate(subscription.id).then(({ status }) => {
      if (status !== 'OK') return;

      sessionStorage.setItem('message', 'Subscription has been reactivated!');
      window.location.reload();
    });
  };

  return (
    <div className={`admin-page react-admin-accounts-edit ${filtersOpen ? 'filters-open' : ''}`}>
      <div className="header">
        <h2>
          <span
            className="back"
            onClick={() => {
              // eslint-disable-next-line
              history.back();
            }}
          >
            &lt;
          </span>
          <a className="back" href="/admin/accounts">Accounts</a>
          {' '}
          /
          {' '}
          { account && `${account.name} / ` }
          { account ? 'Edit' : 'Add New' }
        </h2>
        <button type="button" className="button" onClick={handleUpdate} disabled={!data.name || !data.account_type}>
          { account ? 'Update Account' : 'Create Account' }
        </button>
        { !account.disabled && (
          <div style={{ position: 'relative' }}>
            <button type="button" className="button danger" onClick={() => setDeleteConfirmationVisible(true)}>
              <FiTrash />
            </button>
            {deleteConfirmationVisible && (
              <div className="delete-confirmation">
                <p>Removing an account will affect all relevant records.</p>
                <p>Do you wish to proceed? This action cannot be undone.</p>
                <div className="buttons">
                  <button type="button" className="button danger" onClick={handleDelete}>Yes</button>
                  <button type="button" className="button" onClick={() => setDeleteConfirmationVisible(false)}>No</button>
                </div>
              </div>
            )}
          </div>
        )}

      </div>
      <div className={`form ${filtersOpen ? 'form-filters-open' : ''}`}>
        <div className="tabs">
          <button
            className={`tab ${tab === 'account' ? 'active' : ''}`}
            onClick={() => {
              setTab('account');
              setFiltersOpen(false);
            }}
          >
            Account Detail
          </button>
          <button
            className={`tab ${tab === 'subscription' ? 'active' : ''}`}
            onClick={() => setTab('subscription')}
          >
            Subscription
          </button>
          <button
            className={`tab ${tab === 'users' ? 'active' : ''}`}
            onClick={() => {
              setTab('users');
              setUserFilters({
                name: '', type: '', plan: '', confirmed: '', disabled: '',
              });
              setFilteredUsers(users);
            }}
          >
            User List
          </button>
          <button
            className={`tab ${tab === 'trial' ? 'active' : ''}`}
            onClick={() => {
              setTab('trial');
              setUserFilters({
                name: '', type: '', plan: '', confirmed: '', disabled: '',
              });
              setFilteredUsers(users);
            }}
          >
            Trial
          </button>

          {tab === 'users' && users.length > 0 && (
            <>
              <div style={{ flex: 1 }} />
              <a className="button" href={`/admin/users/new?account_id=${account.id}`}>
                Add New User
                <GoPlus />
              </a>
              <button
                type="button"
                className={`filters-button ${filtersOpen ? 'active' : ''}`}
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                <GoSettings />
              </button>
            </>
          )}
          {tab === 'subscription' && account && (
            <>
              <div style={{ flex: 1 }} />
              <a className="button" href={`/admin/accounts/${account.id}/subscriptions/new`}>
                Add New Subscription
                <GoPlus />
              </a>
              <button
                type="button"
                className={`filters-button ${filtersOpen ? 'active' : ''}`}
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                <GoSettings />
              </button>
            </>
          )}
          {tab === 'trial' && account && (
            <>
              <div style={{ flex: 1 }} />
              <a className="button" href={`/admin/accounts/${account.id}/subscriptions/new?tab=trial`}>
                Add Trial Subscription
                <GoPlus />
              </a>
              <button
                type="button"
                className={`filters-button ${filtersOpen ? 'active' : ''}`}
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                <GoSettings />
              </button>
            </>
          )}
        </div>

        {tab === 'account' && (
          <>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Account Name</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                  />
                </div>
              </div>
            </div>

            {account?.account_type === 'educational_paid'
              && (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Registration link</label>
                      <h3
                        style={{ marginTop: '0', cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(`https://app.ediscoveryassistant.com/registrations/new/education?a=${account.id}`);
                          Snackbar.show('Link copied to the clipboard');
                        }}
                      >
                        {`https://app.ediscoveryassistant.com/registrations/new/education?a=${account.id}`}
                      </h3>
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Account Type</label>
                  <Select
                    value={accountTypes.find((t) => t.value === data.account_type)}
                    options={accountTypes}
                    onChange={(t) => handleChange('account_type', t.value)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Account Category</label>
                  <Select
                    value={accountCategories.map((c) => ({
                      value: c.id,
                      label: c.name,
                    })).find((c) => c.value === data.category_id)}
                    options={accountCategories.map((c) => ({ value: c.id, label: c.name }))}
                    onChange={(t) => handleChange('category_id', t.value)}
                  />
                </div>
              </div>
            </div>

            <h3>Primary Contact</h3>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.first_name}
                    onChange={(e) => handleChange('first_name', e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.last_name}
                    onChange={(e) => handleChange('last_name', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.primary_email}
                    onChange={(e) => handleChange('primary_email', e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.phone_number}
                    onChange={(e) => handleChange('phone_number', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <h3>Billing Contact</h3>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.secondary_first_name}
                    onChange={(e) => handleChange('secondary_first_name', e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.secondary_last_name}
                    onChange={(e) => handleChange('secondary_last_name', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.secondary_email}
                    onChange={(e) => handleChange('secondary_email', e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.secondary_phone_number}
                    onChange={(e) => handleChange('secondary_phone_number', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <h3>Others</h3>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Notes</label>
                  <textarea
                    placeholder="Type Here"
                    className="form-control"
                    value={data.notes}
                    onChange={(e) => handleChange('notes', e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Account Activity</label>
                  <table className="table account-info-table">
                    <tbody>
                      {account_activities.length > 0 ? account_activities.map((a) => (
                        <tr>
                          <td style={{ width: '80%', textAlign: 'left' }}>{activityMessage(a)}</td>
                          <td style={{ width: '20%', textAlign: 'right' }}>{formatDate(a.created_at)}</td>
                        </tr>
                      )) : 'No activity yet.'}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}

        {tab === 'users' && (
          <div className="users-table">
            {filteredUsers.length === 0 ? (
              <div className="placeholder">
                No users found or there are no user added to this account yet.
                <a className="button" href={`/admin/users/new?account_id=${account.id}`}>
                  Add New User
                  <GoPlus />
                </a>
                <div className="empty-image" />
              </div>
            ) : (
              <Table
                users={filteredUsers}
                accountTypes={accountTypes}
                onDeleted={fetchUsers}
                onMove={(user) => {
                  setMoveUser(user);
                  setMoveUserSidebarVisible(true);
                }}
                account={account}
                handleEnableUser={handleEnableUser}
                handleDisableUser={handleDisableUser}
              />
            )}
          </div>
        )}

        { tab === 'subscription' && (
          subscriptions.length === 0 ? (
            <div className="placeholder">
              There are no subscription details yet.
              <div className="empty-image" />
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Time Range</th>
                  <th>Seats</th>
                  <th>Interval</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription) => (
                  <tr>
                    <td>{subscription.plan?.name || ''}</td>
                    <td>
                      {moment(subscription.effective_from).format('MM/DD/YYYY')}
                      {' - '}
                      {moment(subscription.effective_to).format('MM/DD/YYYY')}
                    </td>
                    <td>{`${subscription.seats_used} / ${subscription.number_of_seats}`}</td>
                    <td>{subscription.interval}</td>
                    <td>
                      $
                      {(subscription.amount / 100).toFixed(2)}
                    </td>
                    <td>
                      { subscription.status === 'active' && (
                      <div className="tag success">Active</div>
                      )}

                      { subscription.status === 'cancelled' && (
                      <div className="tag danger">Canceled</div>
                      )}

                      { subscription.status === 'cancelled_at_end_of_period' && (
                      <div className="tag danger">Canceled at the end of period</div>
                      )}
                    </td>
                    <td>
                      <Dropdown className="dots-dropdown" buttonContent="..." align="right">
                        { !subscription.plan.stripe_plan_id && (
                        <DropdownItem
                          onClick={() => { window.location.href = `/admin/accounts/${account.id}/subscriptions/${subscription.id}/edit`; }}
                        >
                          Edit Subscription
                        </DropdownItem>
                        )}
                        {subscription.status === 'cancelled_at_end_of_period' && (
                        <DropdownItem onClick={() => handleReactivateSubscription(subscription)}>
                          Reactivate Subscription
                        </DropdownItem>
                        )}
                        { subscription.status === 'active' && (
                        <DropdownItem onClick={() => handleCancelSubscription(subscription)}>
                          Cancel Subscription
                        </DropdownItem>
                        )}
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )}

        { tab === 'trial' && (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Trial Time Range</th>
                </tr>
              </thead>
              <tbody>
                {trialUsers.map((user) => (
                  <tr>
                    <td>
                      {user.first_name}
                      {' '}
                      {user.last_name}
                    </td>
                    <td>
                      {moment(user.subscription.effective_from).format('MM/DD/YYYY')}
                      {' - '}
                      {moment(user.subscription.effective_to).format('MM/DD/YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {trialUsers.length === 0 && (
              <div className="placeholder">
                There are no trial details yet.
                <div className="empty-image" />
              </div>
            )}
          </>
        )}
      </div>

      { filtersOpen && (
      <div className="sidebar">
        <div className="sidebar-header">
          <h2>Filter By</h2>
        </div>
        <div
          className="sidebar-body"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (tab === 'subscription') handleSubscriptionsSearch();
              else handleUserSearch();
            }
          }}
        >
          {tab === 'users' && (
          <>
            <div className="form-group">
              <input
                className="form-control"
                value={userFilters.name}
                onChange={(e) => setUserFilters({ ...userFilters, name: e.target.value })}
                placeholder="Name/Email"
              />
            </div>

            <div className="form-group">
              <select className="form-control" value={userFilters.type} onChange={(e) => setUserFilters({ ...userFilters, type: e.target.value })}>
                <option value="">Type</option>
                {accountTypes.map((t) => (
                  <option value={t.value}>{t.label}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={userFilters.plan} onChange={(e) => setUserFilters({ ...userFilters, plan: e.target.value })}>
                <option value="">Subscription</option>
                {plans.map((plan) => (
                  <option value={plan.id} key={plan.id}>{plan.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={userFilters.confirmed} onChange={(e) => setUserFilters({ ...userFilters, confirmed: e.target.value })}>
                <option value="">Confirmed?</option>
                <option value>True</option>
                <option value={false}>False</option>
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={userFilters.disabled} onChange={(e) => setUserFilters({ ...userFilters, disabled: e.target.value })}>
                <option value="">Disabled?</option>
                <option value>True</option>
                <option value={false}>False</option>
              </select>
            </div>

            <button type="button" className="button" onClick={handleUserSearch}>Filter</button>
          </>
          )}

          {tab === 'subscription' && (
          <>
            <div className="form-group">
              <select className="form-control" value={subscriptionFilters.plan} onChange={(e) => setSubscriptionFilters({ ...subscriptionFilters, plan: e.target.value })}>
                <option value="">Plan</option>
                {plans.map((plan) => (
                  <option value={plan.id} key={plan.id}>{plan.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={subscriptionFilters.status} onChange={(e) => setSubscriptionFilters({ ...subscriptionFilters, status: e.target.value })}>
                <option value="">Status</option>
                <option value="active">Active</option>
                <option value="cancelled">Canceled</option>
              </select>
            </div>

            <button type="button" className="button" onClick={handleSubscriptionsSearch}>Filter</button>
          </>
          )}

          {tab === 'trial' && (
          <>
            <div className="form-group">
              <input
                className="form-control"
                value={userFilters.name}
                onChange={(e) => setUserFilters({ ...userFilters, name: e.target.value })}
                placeholder="Name/Email"
              />
            </div>

            <button type="button" className="button" onClick={handleUserSearch}>Filter</button>
          </>
          )}

        </div>
      </div>
      )}

      { moveUserSidebarVisible && (
      <MoveToAccountSidebar
        onClose={() => { setMoveUserSidebarVisible(false); setMoveUser(null); }}
        user={moveUser}
        onUpdated={fetchUsers}
      />
      )}
    </div>
  );
}
