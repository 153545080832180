import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { GoSearch } from 'react-icons/all';
import api from '../../services/api';
import SharedModal from './modals/SharedModal';
import UnshareModal from './modals/UnshareModal';
import UnshareNotificationModal from './modals/UnshareNotificationModal';
import UnshareCompleteModal from './modals/UnshareCompleteModal';

export default function ShareSection({
  currentUser, teamMembers, getSharedWithList, fetchCiteLists, selectedCiteList, closeSection,
}) {
  const [userSearchValue, setUserSearchValue] = useState('');
  const [userSearchSuggestions, setUserSearchSuggestions] = useState([]);
  const [shareMembers, setShareMembers] = useState([]);
  const [membersToUnshare, setMembersToUnshare] = useState([]);
  const [sharedModalUsers, setSharedModalUsers] = useState(null);
  const [unshareModalOpen, setUnshareModalOpen] = useState(null);
  const [unshareNotificationsModalOpen, setUnshareNotificationsModalOpen] = useState(false);
  const [unshareCompleteModalOpen, setUnshareCompleteModalOpen] = useState(false);
  const shares = getSharedWithList();

  const clearUserSearchSuggestions = () => {
    if (userSearchValue !== '') return;
    setUserSearchSuggestions([]);
  };

  const closeShareSection = () => {
    setUserSearchValue('');
    setUserSearchSuggestions([]);
    setShareMembers([]);
    setMembersToUnshare([]);
    closeSection();
  };

  const selectUserSearchSuggestion = (e, { suggestion }) => {
    if (shareMembers.find((m) => m.id === suggestion.id)) return;
    setShareMembers([...shareMembers, suggestion]);
  };

  const shareCiteList = (members) => api.cite_lists.share(selectedCiteList.id, {
    user_ids: members.map((m) => m.id),
  }).then(() => {
    setShareMembers([]);
    fetchCiteLists(selectedCiteList.id);
  });

  const unshareCiteList = (members, skipUnshareNotifications) => {
    const isArray = Array.isArray(members);

    return api.cite_lists.unshare(selectedCiteList.id, {
      skip_unshare_notifications: skipUnshareNotifications,
      user_ids: isArray ? members?.map((m) => m.id) : [members.id],
    }).then(() => {
      setShareMembers([]);
      fetchCiteLists(selectedCiteList.id);
    });
  };

  const userInputProps = {
    placeholder: 'Search user name(s) to share',
    value: userSearchValue,
    onChange: (e, { newValue }) => setUserSearchValue(newValue),
  };

  return (
    <div className="side-section share-cite-list">
      <div className="side-section-header">
        <h4 className="side-section-title">
          Share Cite List
          <button
            type="button"
            className="button-clear"
            onClick={closeShareSection}
            data-testid="close-share"
          >
            ✕
          </button>
        </h4>
        <p className="side-section-description">
          You can share a cite list with any other member on your Team or Enterprise account. Click the field to select one or more members of your team to receive the shared cite list. Those receiving the cite list will be able to view and edit the list.
        </p>
      </div>
      <div className="side-section-body">
        <div className="search">
          <Autosuggest
            suggestions={userSearchSuggestions}
            shouldRenderSuggestions={() => true}
            focusInputOnSuggestionClick={false}
            onSuggestionsFetchRequested={({ value }) => {
              const availableMembers = [...teamMembers];
              const inputValue = value.trim().toLowerCase();
              const suggestions = inputValue.length === 0 ? availableMembers : availableMembers.filter((item) => `${item.name}`.toLowerCase().includes(inputValue.toLowerCase()));

              setUserSearchSuggestions(suggestions);
            }}
            onSuggestionsClearRequested={clearUserSearchSuggestions}
            getSuggestionValue={() => ''}
            renderSuggestion={(item) => <div className="suggestion">{item.name}</div>}
            inputProps={userInputProps}
            onSuggestionSelected={selectUserSearchSuggestion}
          />
          <GoSearch />
        </div>
        { shareMembers.length > 0 && (
          <div className="share-members">
            { shareMembers.map((member) => (
              <div className="side-section-item">
                <p className="side-section-item-name">
                  {member.name}
                </p>
                <svg
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setShareMembers(shareMembers.filter((m) => m !== member))}
                >
                  <path d="M14.6667 4.00008H11.3333V3.16675C11.3333 2.50371 11.0699 1.86782 10.6011 1.39898C10.1323 0.93014 9.49637 0.666748 8.83333 0.666748H7.16667C6.50363 0.666748 5.86774 0.93014 5.3989 1.39898C4.93006 1.86782 4.66667 2.50371 4.66667 3.16675V4.00008H1.33333C1.11232 4.00008 0.900358 4.08788 0.744078 4.24416C0.587797 4.40044 0.5 4.6124 0.5 4.83342C0.5 5.05443 0.587797 5.26639 0.744078 5.42267C0.900358 5.57895 1.11232 5.66675 1.33333 5.66675H2.16667V14.8334C2.16667 15.4965 2.43006 16.1323 2.8989 16.6012C3.36774 17.07 4.00363 17.3334 4.66667 17.3334H11.3333C11.9964 17.3334 12.6323 17.07 13.1011 16.6012C13.5699 16.1323 13.8333 15.4965 13.8333 14.8334V5.66675H14.6667C14.8877 5.66675 15.0996 5.57895 15.2559 5.42267C15.4122 5.26639 15.5 5.05443 15.5 4.83342C15.5 4.6124 15.4122 4.40044 15.2559 4.24416C15.0996 4.08788 14.8877 4.00008 14.6667 4.00008ZM6.33333 3.16675C6.33333 2.94573 6.42113 2.73377 6.57741 2.57749C6.73369 2.42121 6.94565 2.33341 7.16667 2.33341H8.83333C9.05435 2.33341 9.26631 2.42121 9.42259 2.57749C9.57887 2.73377 9.66667 2.94573 9.66667 3.16675V4.00008H6.33333V3.16675ZM12.1667 14.8334C12.1667 15.0544 12.0789 15.2664 11.9226 15.4227C11.7663 15.579 11.5543 15.6667 11.3333 15.6667H4.66667C4.44565 15.6667 4.23369 15.579 4.07741 15.4227C3.92113 15.2664 3.83333 15.0544 3.83333 14.8334V5.66675H12.1667V14.8334Z" fill="#000E57" />
                </svg>
              </div>
            ))}
          </div>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            shareCiteList(shareMembers)
              .then(() => setSharedModalUsers(shareMembers));
          }}
        >
          Share Cite List
        </button>

        <button
          type="button"
          className="btn btn-primary btn-add-all"
          onClick={() => {
            shareCiteList(teamMembers)
              .then(() => setSharedModalUsers(teamMembers));
          }}
        >
          Share with all
        </button>

        <button
          type="button"
          className="btn btn-primary btn-add-all"
          onClick={() => {
            setMembersToUnshare(teamMembers);
            setUnshareModalOpen(true);
          }}
        >
          Make private
        </button>

        <div className="side-section-body" style={{ marginTop: '4rem' }}>
          <h4 className="side-section-title">
            Users With Access
          </h4>
          { shares.length > 0 && (
            <div className="share-members">
              { shares.map((share) => (
                <div className="side-section-item">
                  <p className="side-section-item-name">
                    {share.user.name}
                  </p>
                  {/* Allow team members manage cite list shares (except owner). */}
                  { (share.user.id !== currentUser.id)
                    && (
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          setMembersToUnshare(share.user);
                          setUnshareModalOpen(true);
                        }}
                      >
                        <path d="M14.6667 4.00008H11.3333V3.16675C11.3333 2.50371 11.0699 1.86782 10.6011 1.39898C10.1323 0.93014 9.49637 0.666748 8.83333 0.666748H7.16667C6.50363 0.666748 5.86774 0.93014 5.3989 1.39898C4.93006 1.86782 4.66667 2.50371 4.66667 3.16675V4.00008H1.33333C1.11232 4.00008 0.900358 4.08788 0.744078 4.24416C0.587797 4.40044 0.5 4.6124 0.5 4.83342C0.5 5.05443 0.587797 5.26639 0.744078 5.42267C0.900358 5.57895 1.11232 5.66675 1.33333 5.66675H2.16667V14.8334C2.16667 15.4965 2.43006 16.1323 2.8989 16.6012C3.36774 17.07 4.00363 17.3334 4.66667 17.3334H11.3333C11.9964 17.3334 12.6323 17.07 13.1011 16.6012C13.5699 16.1323 13.8333 15.4965 13.8333 14.8334V5.66675H14.6667C14.8877 5.66675 15.0996 5.57895 15.2559 5.42267C15.4122 5.26639 15.5 5.05443 15.5 4.83342C15.5 4.6124 15.4122 4.40044 15.2559 4.24416C15.0996 4.08788 14.8877 4.00008 14.6667 4.00008ZM6.33333 3.16675C6.33333 2.94573 6.42113 2.73377 6.57741 2.57749C6.73369 2.42121 6.94565 2.33341 7.16667 2.33341H8.83333C9.05435 2.33341 9.26631 2.42121 9.42259 2.57749C9.57887 2.73377 9.66667 2.94573 9.66667 3.16675V4.00008H6.33333V3.16675ZM12.1667 14.8334C12.1667 15.0544 12.0789 15.2664 11.9226 15.4227C11.7663 15.579 11.5543 15.6667 11.3333 15.6667H4.66667C4.44565 15.6667 4.23369 15.579 4.07741 15.4227C3.92113 15.2664 3.83333 15.0544 3.83333 14.8334V5.66675H12.1667V14.8334Z" fill="#000E57" />
                      </svg>
                    )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      { Boolean(sharedModalUsers) && (
        <SharedModal
          sharedModalUsers={sharedModalUsers}
          closeModal={() => setSharedModalUsers(null)}
        />
      )}
      { unshareModalOpen && (
        <UnshareModal
          selectedCiteList={selectedCiteList}
          membersToUnshare={membersToUnshare}
          submitUnshare={() => {
            setUnshareNotificationsModalOpen(true);
            setUnshareModalOpen(false);
          }}
          cancelUnshare={() => {
            setUnshareNotificationsModalOpen(false);
            setUnshareModalOpen(false);
            setMembersToUnshare(null);
          }}
        />
      )}
      { unshareNotificationsModalOpen && (
        <UnshareNotificationModal
          membersToUnshare={membersToUnshare}
          submitUnshare={() => {
            setUnshareNotificationsModalOpen(false);
            unshareCiteList(membersToUnshare).then(() => {
              setUnshareCompleteModalOpen(true);
            });
          }}
          cancelUnshare={() => {
            setUnshareNotificationsModalOpen(false);
            unshareCiteList(membersToUnshare, true).then(() => {
              setUnshareCompleteModalOpen(true);
            });
          }}
        />
      )}
      { unshareCompleteModalOpen && (
        <UnshareCompleteModal
          membersToUnshare={membersToUnshare}
          closeModal={() => {
            setUnshareCompleteModalOpen(false);
            setMembersToUnshare(null);
          }}
        />
      )}
    </div>
  );
}
