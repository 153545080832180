import React from 'react';

export default function DeleteListModal({
  citeListUserId, currentUserId, deleteCiteList, closeModal,
}) {
  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md delete-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Delete Cite List
            </h4>
          </div>
          <div className="modal-body">
            <p className="delete-text">
              { citeListUserId !== currentUserId
                ? 'Are you sure you want to Delete? Deleting this shared cite list will remove it only from your account permanently.'
                : 'This cite list is shared with other users. Deleting it will remove it from all users cite lists. '
                + 'Do you wish to delete this cite list?'}
            </p>
            <div className="button-row">
              <div className="modal-button" onClick={deleteCiteList}>
                Delete
              </div>
              <div className="modal-button modal-button-outline" onClick={closeModal}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
